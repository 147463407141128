/* }.App-header-other {
  position: relative;
  margin-left: 2.4rem;
  height: 3.6rem;
  display: inline-flex;
  align-items: center;
  color: white;
} */

.App-header-other {
  line-height: 18px;
  color: #A0A3C4;
  padding: 15px 16px;
  display: block; 
  position: relative;
  height: 3.6rem;   
}

.App-header-other-small {
  line-height: 18px;
  color: #A0A3C4;
  padding: 15px 16px;
  display: block; 
  position: relative;
  height: 3.6rem;   
  text-align: left; 
}



/*
.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #A0A3C4;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
} */



.other-dropdown:hover + .other-menu-items,
.menu-items:hover {
  display: block;
}

.hoverable-menu-container:hover .other-menu-items {
  opacity: 1;
  pointer-events: auto; /* Enable pointer events on hover */
}

.hoverable-menu-container {
  /* Add this class to the container element */
  white-space: nowrap;
  position: absolute;
  top: 130%; /* Position the submenu below the main menu item */
  left: 0;
  z-index: 2; /* Ensure the submenu appears on top of other elements */
  background:rgb(23, 21, 49);
  border: 1px solid #3f3b3b;
  border-radius: 10px;
}

.other-dropdown {
  display: inline-flex;
  position: relative;
  z-index: 1; /* Ensure the submenu appears on top */
}
.other-dropdown-items {
  min-width: 17rem;
  padding-bottom: 0.8rem;
}
.other-dropdown-items:last-child {
  padding-bottom: 0;
}

.other-dropdown:hover {
  background: var(--dark-blue-hover);
  cursor: pointer;
}
.other-dropdown:active {
  background: var(--dark-blue-active);
}

.other-dropdown > button {
  height: 3.6rem;
  border-radius: 0.4rem;
  border: none;
  pointer-events: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
}

.other-menu-item-group {
  display: flex;
  align-items: center;
  margin: 0;
  flex: 1;
}

.other-dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  justify-content: space-between;
}

.other-popup .Modal-content {
  width: 100%;
  max-width: 29.6rem;
  margin: auto;
}

.other-option {
  cursor: pointer;
  background: var(--dark-blue);
  border: 1px solid var(--dark-blue-border);
  border-radius: 0.4rem;
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.other-option:last-child {
  margin-bottom: 0;
}

.other-option img {
  width: 2.5rem;
  margin-right: 1.5rem;
}

.other-option span {
  font-size: var(--font-base);
  letter-spacing: 0.29px;
  color: #fff;
}


.other-menu-item {
  display: flex !important;
  align-items: center;
  font-size: var(--font-base);
  color: #a0a3c4;
  padding-bottom: 1.5rem;
  font-size: var(--font-sm);
  padding: 0.4rem 0.4rem;
  border-radius: 0.4rem;
}
.other-menu-item:hover {
  background: #808aff14 !important;
  border-radius: 0.4rem;
  opacity: 1;
  color: #eee;
}
.other-menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.other-menu-item > a {
  display: inline-flex;
}


.other-dropdown-item-label {
  margin-left: 0.2rem;
}

.other-dropdown-list {
  display: grid;
  grid-template-columns: 1fr;
}

.other-menu-item:hover .other-menu-item-label {
  color: #eee;
}

.active-dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}


@media (max-width: 1033px) {
  .App-header-other {
    position: relative;
    margin-left: 1.6rem;
  }
  .App-header-language {
    margin-left: 0;
  }
}
@media (max-width: 450px) {
  .App-header-other {
    margin-left: 0.6rem;
  }
  .App-header-language {
    margin-left: 0;
  }
  .other-dropdown > button {
    padding: 0 0.5rem;
  }
  .other-dropdown.homepage-header > button {
    padding: 0 0.8rem;
  }
}
