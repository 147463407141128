.Checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.Checkbox .Checkbox-icon {
  font-size: 1.25rem;
  color: linear-gradient(90deg, rgba(255, 157, 22, 0.9906556372549019) 0%, rgba(235, 80, 0, 1) 100%);
  margin-right: 0.62rem;
  margin-bottom: 0;
}

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive {
  color: rgba(255, 255, 255, 0.7);
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font-sm);
}